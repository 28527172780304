import axios from "axios";
import { getHeader } from "./../utility"
import { ADD_MESSAGE } from './../actions/types'

export const getComments = (query) => (dispatch, getState) => {
    return axios
        .get(`/${query}`, getHeader(getState))
        .then(response => {
            if (response.status === 200)
                return { status: response.status, data: response.data }
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: response.status }
            })
        })
        .catch(err => {
            console.error("###### Failed to load roles ############")
        })
}

export const postComment = (query, data) => (dispatch, getState) => {
    return axios
        .post(`/${query}`, data, getHeader(getState))
        .then(response => {
            if (response.status === 201)
                return { status: 201, data: response.data }
        })
        .catch(err => {
            console.error("##### Failed to post comment #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
        })
}

export const deleteComment = (id) => (dispatch, getState) => {
    return axios
        .delete(`/income/comments/${id}/`, getHeader(getState))
        .then(response => {
            if (response.status === 200)
                return { status: 200 }
        })
        .catch(err => {
            console.error("##### Failed to delete comment #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
        })
}

export const getCourseBatch = (query) => (dispatch, getState) => {
    return axios
        .get(`/income/course-batch/?query=${query}`, getHeader(getState))
        .then(response => {
            if (response.status === 200)
                return { status: 200, data: response.data }
        })
        .catch(err => {
            console.error("##### Failed to delete comment #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
        })
}


//          *****            Vendor        *****


export const getVendors = (option) => (dispatch, getState) => {
    return axios
        .get(`/expense/vendor/`, { params: option }, getHeader(getState))
        .then(response => {
            if (response.status === 200) {
                if (option.options) return { status: response.status, data: response.data }
                else return { status: response.status, totalPage: response.data.total_pages, data: response.data.results }
            }
        })
        .catch(err => {
            console.error("##### Failed to get vendor list #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: 400 }
            })
        })
}

export const createVendor = (vendor) => (dispatch, getState) => {
    const data = JSON.stringify(vendor)
    return axios
        .post(`/expense/vendor/`, data, getHeader(getState))
        .then(response => {
            if (response.status === 201) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Vendor Successfully created", status: response.status }
                })
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to create Vendor #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to create vendor", status: err.response.status }
            })
            return { status: err.response.status, data: err.response.data }
        })
}

export const getSingleVendor = (id) => (dispatch, getState) => {
    return axios
        .get(`/expense/vendor/${id}/`, getHeader(getState))
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to get vendor information #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
        })
}

export const updateVendor = (id, vendor) => (dispatch, getState) => {
    const data = JSON.stringify(vendor)
    return axios
        .put(`/expense/vendor/${id}/`, data, getHeader(getState))
        .then(response => {
            if (response.status === 200) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Successfully Updated.", status: response.status }
                })
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to get vendor information #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
            return { status: err.response.status, data: err.response.data }
        })
}

export const deleteVendor = (id) => (dispatch, getState) => {
    return axios
        .delete(`/expense/vendor/${id}/`, getHeader(getState))
        .then(response => {
            if (response.status === 204) {
                return { status: response.status }
            }
        })
        .catch(err => {
            console.error("##### Failed to delete vendor information #####")
            let msg = err.response.data.error || "Something went wrong"
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: msg, status: err.response.status }
            })
        })
}