import axios from "axios";
import { ADD_MESSAGE } from "./types";
import { getHeader } from "./../utility";

export const addIncome = (data, dataType) => (dispatch, getState) => {
    const body = JSON.stringify(data)
    return axios
        .post(`income/${dataType}/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: response.status, msg: "Income Successfully created" }
            })
            return { data: response.data, status: response.status }
        })
        .catch(err => {
            console.error("##### Income create failed")
            let message = err.response.data.error || "Please provide valid data"
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: err.response.status, msg: message }
            })
            return { data: err.response.data, status: err.response.status }
        })
}

export const addIncomeSubmit = (data, dataType) => (dispatch, getState) => {
    const body = JSON.stringify(data)
    return axios
        .post(`income/submit-${dataType}/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: response.status, msg: "Income Successfully created" }
            })
            return { data: response.data, status: response.status }
        })
        .catch(err => {
            console.error("##### Income create failed")
            let message = err.response.data.error || "Please provide valid data"
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: err.response.status, msg: message }
            })
            return { data: err.response.data, status: err.response.status }
        })
}

export const getIncome = (id, incomeType) => (dispatch, getState) => {
    return axios
        .get(`income/${incomeType}/${id}/`, getHeader(getState))
        .then(response => {
            if (response.status === 200)
                return { data: response.data, status: response.status }
        })
        .catch(err => {
            console.error("##### Failed to get Income #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: err.response.status, msg: "Something went wrong!" }
            })
        })
}

export const deleteIncome = (id, dataType, query_params = {}) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['params'] = query_params

    return axios
        .delete(`income/${dataType}/${id}/`, config)
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: 204, msg: "Successfully Deleted" }
            })
            return { data: "Successfully Deleted", status: response.status }
        })
        .catch(err => {
            console.error("#####Failed delete income data#####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: 400, msg: "Failed to delete" }
            })
            return err.response.status
        })
}

export const getEmployees = () => (dispatch, getState) => {
    return axios
        .get(`auth/employee/?reference=income`, getHeader(getState))
        .then(response => {
            if (response.status === 200)
                return { data: response.data, status: response.status }
        })
        .catch(err => {
            return { data: [], status: err.response.status }
        })
}

export const getIncomeList = (dataType, params) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['params'] = params
    return axios
        .get(`income/${dataType}/`, config)
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, totalPage: response.data.total_pages, data: response.data.results }
            }
        })
        .catch(err => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: 500, msg: "Something went wrong." }
            })
        })
}

export const getStudentList = () => (dispatch, getState) => {
    return axios
        .get(`income/students-list/`, getHeader(getState))
        .then(response => {
            if (response.status === 200)
                return { data: response.data, status: response.status }
        })
        .catch(err => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: 500, msg: "Something went wrong." }
            })
        })
}

export const installmentUpdate = (data, id, incomeType) => (dispatch, getState) => {

    const body = JSON.stringify(data)
    return axios
        .post(`income/${incomeType}/${id}/installment/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: response.status, msg: response.success }
            })
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            console.error("Insallment add failed")
            dispatch({
                type: ADD_MESSAGE,
                payload: { status: err.response.status, msg: err.response.data.error }
            })
            return { status: err.response.status }
        })
}

export const updateIncome = (data, id, incomeType) => (dispatch, getState) => {
    const body = JSON.stringify(data)
    return axios
        .patch(`income/${incomeType}/${id}/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Income Updated Successfully", status: response.status }
            })
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
        })
}

export const retrieveStudent = (id) => (dispatch, getState) => {
    return axios
        .get(`income/student/${id}/`, getHeader(getState))
        .then(response => {
            if (response.status === 200)
                return { status: response.status, data: response.data }
        })
        .catch(err => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
        })
}



export const getEnrolledStudents = (batch_name, income_type) => (dispatch, getState) => {
    const job_support = 'https://stms.qatekinternal.com/job-support/partner-api/job-support-list/'
    const software_training = 'https://stms.qatekinternal.com/records/partner-api/enrolled-student-list/'
    return axios({
        method: 'GET',
        url: income_type === "training" ? software_training : job_support,
        headers: {
            'Authorization': "Bearer " + localStorage.getItem('access')
        },
        params: {
            batch_name: batch_name
        }
    })
        .then((response) => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch((error) => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to load students data", statu: 400 },
            })
        })
}


export const getBatchList = () => (dispatch, getState) => {
    return axios({
        method: 'GET',
        url: 'https://stms.qatekinternal.com/records/partner-api/batch-list/',
        headers: {
            'Authorization': "Bearer " + localStorage.getItem('access')
        },
    })
        .then((response) => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch((error) => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to load batch data", statu: 400 },
            })
        })
}


export const getJobSupportStudent = (batch_name) => (dispatch, getState) => {
    return axios({
        method: 'GET',
        url: 'https://stms.qatekinternal.com/records/job-support/partner-api/job-support-list/',
        headers: {
            'Authorization': "Bearer " + localStorage.getItem('access')
        },
        params: {
            batch_name: batch_name
        }
    })
        .then((response) => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }

        })
        .catch((error) => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to load students data", statu: 400 },
            })
        })
}