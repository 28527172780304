import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { getEmployeesContructor } from './employee';
import { useDispatch } from 'react-redux';
import { ADD_MESSAGE } from './../actions/types'
import Select from 'react-select';
import { FiEdit3, FiTrash2 } from "react-icons/fi"
import TextInput from "../components/TextInput";
import FilledRoundedButton from "../components/Button/FilledRoundedButton"


const ConsultantInvoiceForm = () => {
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [formInvoice, setFormInvoice] = useState({
        bill_to: "",
        bill_from: "",
        address: "",
        invoice_no: "",
        invoice_date: "",
        due_date: "",
        subject: "",
        currency: "USD",
        tax_included: false,
        total: 0,
        due_amount: "",
        paid_amount: "",
        notes_terms: "",
        bill_type: "consultant",
        email: '',
        contact_number: '',
        items: [{ item_name: "", description: "", quantity: 1, price: "", amount: "" }]
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sendingEmailFor, setSendingEmailFor] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteInvoiceId, setDeleteInvoiceId] = useState(null);
    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const [filterOpen, setFilterOpen] = useState(false);

    const [filterParams, setFilterParams] = useState({
        bill_type: '',
        name: '',
        invoice_no: '',
        invoice_date_from: '',
        invoice_date_to: '',
        due_date_from: '',
        due_date_to: '',
        status: '',
    });

    // Function to fetch invoices with filter parameters
    const fetchInvoices = useCallback(async () => {
        const token = localStorage.getItem('access');
        setInvoiceLoading(true);
        try {
            const { bill_type, name, invoice_no, invoice_date_from, invoice_date_to, due_date_from, due_date_to, status } = filterParams;

            // Construct query parameters based on filter values
            const params = new URLSearchParams();
            if (bill_type) params.append('bill_type', bill_type);
            if (name) params.append('name', name);
            if (invoice_no) params.append('invoice_no', invoice_no);
            if (invoice_date_from) params.append('invoice_date_from', invoice_date_from);
            if (invoice_date_to) params.append('invoice_date_to', invoice_date_to);
            if (due_date_from) params.append('due_date_from', due_date_from);
            if (due_date_to) params.append('due_date_to', due_date_to);
            if (status) params.append('status', status);

            // Fetch invoices from API
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}api/invoices/?${params.toString()}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            // Sort invoices by invoice_date (descending)
            const sortedInvoices = response.data.sort((a, b) => new Date(b.invoice_date) - new Date(a.invoice_date));
            setInvoices(sortedInvoices);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        } finally {
            setInvoiceLoading(false);
        }
    }, [filterParams]);

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    // Handle changes in filter inputs
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterParams({
            ...filterParams,
            [name]: value,
        });
    };


    const toggleFilter = () => {
        setFilterOpen(!filterOpen);

    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Automatically set the email and address when "Bill From" is selected
        if (name === "bill_from") {
            const selectedEmployee = data.find(employee => employee.name === value);
            if (selectedEmployee) {
                // Format the full address as a single string
                const fullAddress = [
                    selectedEmployee.street,
                    selectedEmployee.city,
                    selectedEmployee.state,
                    selectedEmployee.country,
                    selectedEmployee.zipcode,
                ].filter(Boolean).join(', ');

                // Update formInvoice state with the selected student's email and address
                setFormInvoice({
                    ...formInvoice,
                    [name]: value,
                    email: selectedEmployee.email,
                    address: fullAddress,
                    contact_number: selectedEmployee.phone
                });
            } else {
                setFormInvoice({ ...formInvoice, [name]: value });
            }
        } else {
            setFormInvoice({ ...formInvoice, [name]: value });
        }
    };


    const handleItemChange = (index, e) => {
        const { name, value } = e.target;
        const items = [...formInvoice.items];
        items[index][name] = value;
        if (name === "quantity" || name === "price") {
            items[index]["amount"] = items[index]["quantity"] * items[index]["price"];
        }
        setFormInvoice({ ...formInvoice, items });
    };

    const handleAddItem = () => {
        setFormInvoice({
            ...formInvoice,
            items: [...formInvoice.items, { item_name: "", description: "", quantity: 1, price: "", amount: "" }]
        });
    };

    const handleRemoveItem = (index) => {
        const items = formInvoice.items.filter((_, i) => i !== index);
        setFormInvoice({ ...formInvoice, items });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access');

        // if (selectedInvoice) {
        //     try {
        //         const response = await axios.put(`${process.env.REACT_APP_BASE_URL}api/invoices/${selectedInvoice.id}/`, formInvoice, {
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': `Bearer ${token}`,
        //             },
        //         });
        //         dispatch({
        //             type: ADD_MESSAGE,
        //             payload: { msg: "Invoice updated successfully!", status: response.status }
        //         });
        //     } catch (err) {
        //         console.error("Error updating invoice:", err);
        //         dispatch({
        //             type: ADD_MESSAGE,
        //             payload: { msg: "Failed to update invoice", status: err.response?.status || 500 }
        //         });
        //     }
        // } else {
        //     try {
        //         const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/invoices/`, formInvoice, {
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': `Bearer ${token}`,
        //             },
        //         });
        //         dispatch({
        //             type: ADD_MESSAGE,
        //             payload: { msg: "Invoice added successfully!", status: response.status }
        //         });
        //     } catch (err) {
        //         console.error("Error adding invoice:", err);
        //         dispatch({
        //             type: ADD_MESSAGE,
        //             payload: { msg: "Failed to create invoice", status: err.response?.status || 500 }
        //         });
        //     }
        // }

        try {
            let response;

            if (selectedInvoice) {
                // Update existing invoice
                response = await axios.put(`${process.env.REACT_APP_BASE_URL}api/invoices/${selectedInvoice.id}/`, formInvoice, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Invoice updated successfully!", status: response.status }
                });
            } else {
                // Create new invoice
                response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/invoices/`, formInvoice, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Invoice added successfully!", status: response.status }
                });
            }

        } catch (err) {
            console.error("Error in invoice submission:", err);

            const errorData = err.response?.data || {};
            let errorMsg = "Failed: ";

            for (const messages of Object.values(errorData)) {
                errorMsg += `${messages}. `;
            }
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: errorMsg, status: err.response?.status || 500 }
            });
        }

        fetchInvoices();
        setFormInvoice({
            bill_to: "",
            bill_from: "",
            address: "",
            invoice_no: "",
            invoice_date: "",
            due_date: "",
            subject: "",
            currency: "USD",
            tax_included: false,
            total: 0,
            due_amount: "",
            paid_amount: "",
            notes_terms: "",
            bill_type: "consultant",
            email: '',
            contact_number: '',
            items: [{ item_name: "", description: "", quantity: 1, price: "", amount: "" }]
        });
        setSelectedInvoice(null);
        closeModal();
    };


    const handleEdit = (invoice) => {
        setSelectedInvoice(invoice);
        setFormInvoice(invoice);
        openModal();
    };

    const handleDeleteConfirmation = (id) => {
        setDeleteInvoiceId(id);
        setIsDeleteModalOpen(true);
    };

    const handleDelete = async () => {
        const token = localStorage.getItem('access');
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}api/invoices/${deleteInvoiceId}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            fetchInvoices();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting invoice:", error);
        }
    };

    const handleSendEmail = async (invoiceId) => {
        setSendingEmailFor(invoiceId);
        const token = localStorage.getItem('access');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/invoices/${invoiceId}/send_email/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Email sent successfully!", status: response.status }
            });
        } catch (err) {
            console.error("Error sending email:", err);
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to send email", status: err.response?.status || 500 }
            });
        } finally {
            setSendingEmailFor(null);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setFormInvoice({
            bill_to: "",
            bill_from: "",
            address: "",
            invoice_no: "",
            invoice_date: "",
            due_date: "",
            subject: "",
            currency: "USD",
            tax_included: false,
            total: 0,
            due_amount: "",
            paid_amount: "",
            notes_terms: "",
            bill_type: "consultant",
            email: '',
            contact_number: '',
            items: [{ item_name: "", description: "", quantity: 1, price: "", amount: "" }]
        });
        setSelectedInvoice(null);
    };

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    // Fetch employee data
    useEffect(() => {
        const fetchAllPages = async () => {
            try {
                const initialResult = await dispatch(getEmployeesContructor({ page: 1 }));
                if (!initialResult || !initialResult.data) {
                    throw new Error('Failed to fetch initial page');
                }

                const totalPages = initialResult.totalPage;
                const allPages = [];

                const pagePromises = Array.from({ length: totalPages }, (_, i) =>
                    dispatch(getEmployeesContructor({ page: i + 1 }))
                );

                const results = await Promise.all(pagePromises);

                results.forEach(result => {
                    if (result && result.data && Array.isArray(result.data)) {
                        allPages.push(...result.data);
                    }
                });

                setData(allPages);
            } catch (err) {
                console.error('Fetching data failed:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchAllPages();
    }, [dispatch]);


    const options = data.map(employee => ({
        value: employee.name,
        label: employee.name
    }));

    const handleSelectChange = selectedOption => {
        handleChange({ target: { name: 'bill_from', value: selectedOption.value } });
    };


    // test
    const [showReceiptForm, setShowReceiptForm] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState('');
    const [notes, setNotes] = useState('');
    const [sendingReceipt, setSendingReceipt] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('Bank Payment');
    const [paymentDate, setPaymentDate] = useState('');
    const [activeDropdownId, setActiveDropdownId] = useState(null);


    const handleDropdownToggle = (invoiceId) => {
        console.log("Toggled Invoice ID: ", invoiceId);

        if (activeDropdownId === invoiceId) {
            setActiveDropdownId(null);
        } else {
            setActiveDropdownId(invoiceId);
        }
    };


    const handleSendReceiptClick = () => {
        setShowReceiptForm(!showReceiptForm);
    };



    const sendReceipt = (invoiceId) => {
        console.log("Sending receipt for Invoice ID:", invoiceId);
        setSendingReceipt(true);
        const token = localStorage.getItem('access');

        const formattedPaymentAmount = parseFloat(paymentAmount).toFixed(2);

        const receiptData = {
            payment_amount: formattedPaymentAmount,
            notes: notes,
            payment_method: paymentMethod,
            payment_date: paymentDate,
        };

        fetch(`${process.env.REACT_APP_BASE_URL}api/invoices/${invoiceId}/send_receipt/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(receiptData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setSendingReceipt(false);
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {
                        msg: `Receipt sent successfully!`,
                        status: 'success'
                    }
                });
                setShowReceiptForm(false);
            })
            .catch(error => {
                setSendingReceipt(false);
                console.error('Error sending receipt:', error);
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: `Error sending receipt: ${error.message}`, status: 'error' }
                });
            });
    };

    const handleEmailStatusChange = (invoiceId, newStatus) => {
        const token = localStorage.getItem('access');
        const updatedInvoice = invoices.find(invoice => invoice.id === invoiceId);
        const updatedData = {
            ...updatedInvoice,
            email_status: newStatus,
        };

        setInvoices(prevInvoices =>
            prevInvoices.map(invoice =>
                invoice.id === invoiceId ? { ...invoice, email_status: newStatus } : invoice
            )
        );

        fetch(`${process.env.REACT_APP_BASE_URL}api/invoices/${invoiceId}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Email Status updated successfully:', data);
            })
            .catch(error => {
                console.error('Error updating Email status:', error);
            });
    };

    const inputStyle = {
        backgroundColor: '#EAEFF4',
        boxSizing: 'border-box',
        border: '2px solid #CDCDCD',
        borderRadius: '5px',
        padding: '0.5rem',
        margin: '0.5rem',
        fontSize: '1rem',
        zIndex: 0,
        position: 'relative'
    };

    const labelStyle = {
        position: 'absolute',
        top: '0px',
        left: '10px',
        padding: '0 1px',
        backgroundColor: '#EAEFF4',
        color: '#006CB0',
        fontSize: '14px',
        transition: '0.5s',
        borderRadius: '4px',
        zIndex: 0,
        fontWeight: 600,
    }

    const EmailstatusStyles = {
        Sent: {
            backgroundColor: '#28a745',
            color: '#fff',
        },
        Unsent: {
            backgroundColor: '#ffc107',
            color: '#333',
        },
    };

    const statusStyles = {
        Paid: {
            backgroundColor: '#b8daff',
            color: '#004085',
        },
        Overdue: {
            backgroundColor: '#f8d7da',
            color: '#842029',
        },
        Partial: {
            backgroundColor: '#fff3cd',
            color: '#856404',
        },
        Unpaid: {
            backgroundColor: '#f0e0e0',
            color: '#7c7c7c',
        },
    };

    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            margin: '0 auto',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'sticky',
            top: -20,
            marginBottom: '10px',
            backgroundColor: '#fff',
            zIndex: 10,
            padding: '0 10px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
        },
        button: {
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginRight: "10px"
        },
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            opacity: isModalOpen ? 1 : 0,
            pointerEvents: isModalOpen ? 'auto' : 'none',
            transition: 'opacity 0.1s ease'
        },
        modalContent: {
            backgroundColor: 'white',
            borderRadius: '5px',
            padding: '20px',
            width: '80%',
            maxWidth: '600px',
            maxHeight: '80%',
            overflow: 'auto'
        },
        close: {
            cursor: 'pointer',
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'red'
        },

        formGroup: {
            marginBottom: '15px'
        },
        label: {
            display: 'block',
            marginBottom: '5px'
        },
        input: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
        },
        select: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
        },
        textarea: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
        },
        itemGroup: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px'
        },
        itemInput: {
            marginBottom: '10px'
        },
        removeButton: {
            padding: '5px 10px',
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            alignSelf: 'flex-end'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px'
        },
        th: {
            border: '1px solid #ddd',
            padding: '8px',
            backgroundColor: '#f2f2f2'
        },
        td: {
            border: '1px solid #ddd',
            padding: '8px'
        },
        invoiceButton: {
            marginRight: '10px'
        },
    };

    return (
        <div style={styles.container}>

            <div>
                <div className="align-left border border-radius" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <FilledRoundedButton name="Filters" size="medium" onClickHandler={toggleFilter} color="secondary" />

                    <button style={styles.button} onClick={openModal}>Add Invoice</button>
                </div>
                <br />

                {filterOpen && (
                    <div className="filter-container">
                        <div className="filter-body-left">
                            <TextInput
                                name="invoice_date_from"
                                type="date"
                                placeholder="Invoice Date-From"
                                size="small"
                                onChangeHandler={handleFilterChange}
                                value={filterParams.invoice_date_from}
                            />
                            <TextInput
                                name="invoice_date_to"
                                type="date"
                                placeholder="Invoice Date-To"
                                size="small"
                                onChangeHandler={handleFilterChange}
                                value={filterParams.invoice_date_to}
                            />
                            <div style={{ width: '20px' }}>

                            </div>
                            <TextInput
                                name="due_date_from"
                                type="date"
                                placeholder="Due Date-From"
                                size="small"
                                onChangeHandler={handleFilterChange}
                                value={filterParams.due_date_from}
                            />
                            <TextInput
                                name="due_date_to"
                                type="date"
                                placeholder="Due Date-To"
                                size="small"
                                onChangeHandler={handleFilterChange}
                                value={filterParams.due_date_to}
                            />
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="text"
                                    name="invoice_no"
                                    style={inputStyle}
                                    value={filterParams.invoice_no}
                                    onChange={handleFilterChange}
                                />
                                <label style={labelStyle}>Filter by Invoice No</label>
                            </div>

                            <div style={{ position: 'relative' }}>
                                <input
                                    type="text"
                                    name="name"
                                    style={inputStyle}
                                    value={filterParams.name}
                                    onChange={handleFilterChange}
                                />
                                <label style={labelStyle}>Filter by Name</label>
                            </div>

                            <div>
                                <select
                                    id="status"
                                    value={filterParams.status}
                                    onChange={(e) => setFilterParams({ ...filterParams, status: e.target.value })}
                                    style={inputStyle}
                                >
                                    <option value="">All Status</option>
                                    <option value="Unpaid">Unpaid</option>
                                    <option value="Overdue">Overdue</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Partial">Partial</option>
                                    <option value="Invalid">Invalid</option>
                                </select>
                            </div>
                        </div>

                    </div>
                )}

            </div>

            {/* Modal */}
            <div style={styles.modal}>
                <div style={styles.modalContent}>
                    <div style={styles.formHeader}>
                        <h3>{selectedInvoice ? "Edit Invoice" : "Add Invoice"}</h3>
                        <span style={styles.close} onClick={closeModal}>&times;</span>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Bill To</label>
                                <input style={styles.input} type="text" name="bill_to" value={formInvoice.bill_to} onChange={handleChange} required />
                            </div>


                            <div style={styles.formGroup}>
                                <label style={styles.label}>Bill From:</label>
                                <Select
                                    name="bill_from"
                                    value={options.find(option => option.value === formInvoice.bill_from)}
                                    onChange={handleSelectChange}
                                    options={options}
                                    isLoading={loading}
                                    placeholder="Select Bill From"
                                />
                            </div>


                            <div style={styles.formGroup}>
                                <label style={styles.label}>Recipient Address</label>
                                <textarea
                                    style={styles.input}
                                    name="address"
                                    value={formInvoice.address}
                                    onChange={handleChange}
                                />
                            </div>


                            <div style={styles.formGroup}>
                                <label style={styles.label}>Recipient Email</label>
                                <input style={styles.input} name="email" value={formInvoice.email} onChange={handleChange} />
                            </div>

                            <div style={styles.formGroup}>
                                <label style={styles.label}>Contact Number</label>
                                <input style={styles.input} name="contact_number" value={formInvoice.contact_number} onChange={handleChange} />
                            </div>


                            <div style={styles.formGroup}>
                                <label style={styles.label}>Invoice No</label>
                                <input style={styles.input} type="text" name="invoice_no" value={formInvoice.invoice_no} onChange={handleChange} required />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Invoice Date</label>
                                <input style={styles.input} type="date" name="invoice_date" value={formInvoice.invoice_date} onChange={handleChange} required />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Due Date</label>
                                <input style={styles.input} type="date" name="due_date" value={formInvoice.due_date} onChange={handleChange} required />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Subject</label>
                                <input style={styles.input} type="text" name="subject" value={formInvoice.subject} onChange={handleChange} required />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Currency</label>
                                <select
                                    style={styles.select}
                                    name="currency"
                                    value={formInvoice.currency}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="USD">USD</option>
                                    <option value="EUR">EUR</option>
                                    <option value="AUD">AUD</option>
                                    <option value="BDT">BDT</option>
                                    <option value="JPY">JPY</option>
                                    <option value="GBP">GBP</option>
                                    <option value="CAD">CAD</option>
                                    <option value="CHF">CHF</option>
                                    <option value="CNY">CNY</option>
                                    <option value="SEK">SEK</option>
                                    <option value="NZD">NZD</option>
                                    <option value="MXN">MXN</option>
                                    <option value="SGD">SGD</option>
                                    <option value="HKD">HKD</option>
                                    <option value="NOK">NOK</option>
                                    <option value="KRW">KRW</option>
                                    <option value="TRY">TRY</option>
                                    <option value="RUB">RUB</option>
                                    <option value="INR">INR</option>
                                    <option value="BRL">BRL</option>
                                    <option value="ZAR">ZAR</option>
                                </select>
                            </div>


                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <label style={{ marginRight: '10px' }}>Tax Included :</label>
                                <input type="checkbox" name="tax_included" checked={formInvoice.tax_included} onChange={(e) => setFormInvoice({ ...formInvoice, tax_included: e.target.checked })} />
                            </div>

                            <div style={styles.formGroup}>
                                <label style={styles.label}>Total</label>
                                <input style={styles.input} type="number" name="total" value={formInvoice.total} onChange={handleChange} />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Due</label>
                                <input style={styles.input} type="number" name="due_amount" value={formInvoice.due_amount} onChange={handleChange} />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Paid</label>
                                <input style={styles.input} type="number" name="paid_amount" value={formInvoice.paid_amount} onChange={handleChange} />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Notes/Terms</label>
                                <textarea style={styles.textarea} name="notes_terms" value={formInvoice.notes_terms} onChange={handleChange}></textarea>
                            </div>

                            <div>
                                <h4>Items</h4>
                                {formInvoice.items.map((item, index) => (
                                    <div key={index} style={styles.itemGroup}>
                                        <input style={{ ...styles.input, ...styles.itemInput }} type="text" name="item_name" value={item.item_name} onChange={(e) => handleItemChange(index, e)} placeholder="Item No" required />
                                        <div style={styles.itemInput}>
                                            <textarea
                                                name="description"
                                                placeholder="Description"
                                                value={item.description}
                                                onChange={(e) => handleItemChange(index, e)}
                                                style={styles.textarea}
                                                required
                                            />
                                        </div>
                                        <input style={{ ...styles.input, ...styles.itemInput }} type="number" name="quantity" value={item.quantity} onChange={(e) => handleItemChange(index, e)} placeholder="Quantity" required />
                                        <input style={{ ...styles.input, ...styles.itemInput }} type="number" name="price" value={item.price} onChange={(e) => handleItemChange(index, e)} placeholder="Price" required />
                                        <input style={{ ...styles.input, ...styles.itemInput }} type="number" name="amount" value={item.amount} readOnly placeholder="Amount" />
                                        <button type="button" style={styles.removeButton} onClick={() => handleRemoveItem(index)}>Remove</button>
                                    </div>
                                ))}
                                <button type="button" style={styles.button} onClick={handleAddItem}>Add Item</button>
                            </div>

                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <button type="submit" style={styles.button}>
                                    Submit
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.th}>Invoice Number</th>
                        <th style={styles.th}>Bill To</th>
                        <th style={styles.th}>Bill From</th>
                        <th style={styles.th}>Invoice Date</th>
                        <th style={styles.th}>Due Date</th>
                        <th style={styles.th}>Total</th>
                        <th style={styles.th}>Paid</th>
                        <th style={styles.th}>Due</th>
                        <th style={styles.th}>Status</th>
                        <th style={styles.th}>Mail Status</th>
                        <th style={styles.th}>Actions</th>
                    </tr>
                </thead>

                {invoiceLoading ? (
                    <p>Loading...</p>
                ) : (
                    <tbody>
                        {invoices.filter(invoice => invoice.bill_type === "consultant").map((invoice) => (
                            <tr key={invoice.id}>
                                <td style={styles.td}>{invoice.invoice_no}</td>
                                <td style={styles.td}>{invoice.bill_to}</td>
                                <td style={styles.td}>{invoice.bill_from}</td>
                                <td style={{ ...styles.td, whiteSpace: 'nowrap' }}>{invoice.invoice_date}</td>
                                <td style={{ ...styles.td, whiteSpace: 'nowrap' }}>{invoice.due_date}</td>

                                <td style={{ ...styles.td, whiteSpace: 'nowrap' }}>
                                    {invoice.currency === 'BDT' ? 'BDT' : '$'} {invoice.total ? invoice.total : '0.00'}
                                </td>
                                <td style={{ ...styles.td, whiteSpace: 'nowrap' }}>
                                    {invoice.currency === 'BDT' ? 'BDT' : '$'} {invoice.paid_amount ? invoice.paid_amount : '0.00'}
                                </td>
                                <td style={{ ...styles.td, whiteSpace: 'nowrap' }}>
                                    {invoice.currency === 'BDT' ? 'BDT' : '$'} {invoice.due_amount ? invoice.due_amount : '0.00'}
                                </td>

                                <td style={styles.td}>
                                    <span
                                        style={{
                                            ...statusStyles[invoice.status],
                                            padding: '5px',
                                            borderRadius: '5px',
                                            display: 'inline-block',
                                            width: '100%',
                                            textAlign: 'center',
                                            border: '1px solid #ccc'
                                        }}
                                    >
                                        {invoice.status}
                                    </span>
                                </td>

                                <td style={styles.td}>
                                    <select
                                        value={invoice.email_status || 'Unsent'}
                                        onChange={(e) => handleEmailStatusChange(invoice.id, e.target.value)}
                                        style={{
                                            ...EmailstatusStyles[invoice.email_status] || EmailstatusStyles.Unsent,
                                            padding: '5px',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <option value="Sent" style={EmailstatusStyles.Sent}>Sent</option>
                                        <option value="Unsent" style={EmailstatusStyles.Unsent}>Unsent</option>
                                    </select>
                                </td>

                                <td style={{ padding: '10px', verticalAlign: 'top', border: '1px solid #ddd' }}>

                                    <div style={{ display: 'flex', gap: '15px', justifyContent: 'center', alignItems: 'center' }}>

                                        <div className="align-left gap-1" id="column1"
                                            style={{
                                                padding: '5px',
                                                border: '1px solid blue',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleEdit(invoice)}>
                                            <FiEdit3 color="#006CB0" />
                                            <p>Edit</p>
                                        </div>

                                        <div className="align-left gap-1" id="column2"
                                            style={{
                                                padding: '5px',
                                                border: '1px solid red',
                                                borderRadius: '5px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleDeleteConfirmation(invoice.id)}>
                                            <FiTrash2 color="#C0392B" />
                                            <p>Delete</p>
                                        </div>


                                        <div style={{ position: 'relative' }}>
                                            <button
                                                style={{
                                                    padding: '12px',
                                                    backgroundColor: '#007bff',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handleDropdownToggle(invoice.id)}
                                            >
                                                Mail Actions
                                            </button>
                                            {activeDropdownId === invoice.id && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '110%',
                                                        left: 0,
                                                        backgroundColor: '#fff',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                                        zIndex: 1000,
                                                        width: '130px',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            display: 'block',
                                                            width: '100%',
                                                            padding: '10px',
                                                            backgroundColor: sendingEmailFor === invoice.id ? '#6c757d' : '#007bff',
                                                            color: '#fff',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            cursor: sendingEmailFor === invoice.id ? 'not-allowed' : 'pointer',
                                                            marginBottom: '5px'
                                                        }}
                                                        onClick={() => handleSendEmail(invoice.id)}
                                                        disabled={sendingEmailFor === invoice.id}
                                                    >
                                                        {sendingEmailFor === invoice.id ? 'Sending...' : 'Send Email'}
                                                    </button>
                                                    <button
                                                        style={{
                                                            display: 'block',
                                                            width: '100%',
                                                            padding: '10px',
                                                            backgroundColor: '#28a745',
                                                            color: '#fff',
                                                            border: 'none',
                                                            borderRadius: '5px'
                                                        }}
                                                        onClick={handleSendReceiptClick}
                                                    >
                                                        Send Receipt
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                    {showReceiptForm && activeDropdownId === invoice.id && (
                                        <div
                                            style={{
                                                position: 'fixed',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 2000,
                                                opacity: showReceiptForm ? 1 : 0,
                                                pointerEvents: showReceiptForm ? 'auto' : 'none',
                                                transition: 'opacity 0.1s ease'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '5px',
                                                    padding: '20px',
                                                    width: '80%',
                                                    maxWidth: '400px',
                                                    maxHeight: '80%',
                                                    overflow: 'auto',
                                                    position: 'relative'
                                                }}
                                            >
                                                <button
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        right: '10px',
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        fontSize: '24px',
                                                        fontWeight: 'bold',
                                                        color: 'red'
                                                    }}
                                                    onClick={() => setShowReceiptForm(false)}
                                                >
                                                    ×
                                                </button>
                                                <h3>Send Receipt <span style={{ fontWeight: 'normal' }}>for {invoice.invoice_no}</span></h3>

                                                <div style={{ marginBottom: '15px' }}>
                                                    <label style={{ display: 'block', marginBottom: '5px', textAlign: 'left' }}>
                                                        Payment Amount:
                                                        <input
                                                            type="number"
                                                            value={paymentAmount}
                                                            onChange={(e) => setPaymentAmount(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                marginTop: '5px'
                                                            }}
                                                        />
                                                    </label>
                                                </div>

                                                <div style={{ marginBottom: '15px' }}>
                                                    <label style={{ display: 'block', marginBottom: '5px', textAlign: 'left' }}>
                                                        Notes:
                                                        <textarea
                                                            value={notes}
                                                            onChange={(e) => setNotes(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                marginTop: '5px'
                                                            }}
                                                        ></textarea>
                                                    </label>
                                                </div>

                                                <div style={{ marginBottom: '15px' }}>
                                                    <label style={{ display: 'block', marginBottom: '5px', textAlign: 'left' }}>
                                                        Payment Method:
                                                        <select
                                                            value={paymentMethod}
                                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                marginTop: '5px'
                                                            }}
                                                        >
                                                            <option value="Bank Payment">Bank Payment</option>
                                                            <option value="Cash">Cash</option>
                                                            <option value="Check">Check</option>
                                                            <option value="Credit Card">Credit Card</option>
                                                            <option value="Paypal">Paypal</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div style={{ marginBottom: '15px' }}>
                                                    <label style={{ display: 'block', marginBottom: '5px', textAlign: 'left' }}>
                                                        Payment Date:
                                                        <input
                                                            type="date"
                                                            value={paymentDate}
                                                            onChange={(e) => setPaymentDate(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                marginTop: '5px'
                                                            }}
                                                        />
                                                    </label>
                                                </div>

                                                <button
                                                    onClick={() => sendReceipt(invoice.id)}
                                                    disabled={sendingReceipt}
                                                    style={{
                                                        backgroundColor: '#4caf50',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    {sendingReceipt ? 'Sending...' : 'Send Receipt'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>

                )}

            </table>

            {isDeleteModalOpen && (
                <div className="modal" style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                    width: '100%',
                    height: '100%'
                }}>
                    <div className="modal-content" style={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        padding: 20,
                        maxWidth: 300,
                        textAlign: 'center',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        transition: '0.3s'
                    }}>
                        <h2 style={{ marginBottom: 10 }}>Delete Invoice</h2>
                        <p style={{ marginBottom: 20 }}>Are you sure you want to delete this invoice?</p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button
                                onClick={handleDelete}
                                style={{
                                    backgroundColor: '#f44336',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: 5,
                                    border: 'none',
                                    cursor: 'pointer',
                                    marginRight: 10
                                }}
                            >
                                Delete
                            </button>
                            <button
                                onClick={() => setIsDeleteModalOpen(false)}
                                style={{
                                    backgroundColor: '#ccc',
                                    color: 'black',
                                    padding: '10px 20px',
                                    borderRadius: 5,
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConsultantInvoiceForm;
