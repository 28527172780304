import React, { useState, useEffect } from 'react'
import "./table.css"

import { useSelector } from "react-redux"
import { FiEdit3, FiTrash2, FiCheckCircle, FiFileText, FiList } from "react-icons/fi"
import { FaCheckCircle } from "react-icons/fa"
import { BsFillChatSquareDotsFill } from "react-icons/bs"
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';
import Modal from "../Modal"
import ButtonOulined from '../Button/ButtonOulined'
import FilledRoundedButton from "../Button/FilledRoundedButton"
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function SimpleTable({ header, values = [], onDelete, onEdit, tableBody, onCheck, onMore, activeRow = -1, page,
    handlePage, totalPage, total, onMark, markedItem }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const role = useSelector((state) => state.auth.role)

    const handleOpenPopOver = (event, value) => {
        setAnchorEl(event.currentTarget)
        setOpen(value.id)
    }

    const handleClosePopOver = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const actionEdtDel = (onDelete, onEdit, value) => (
        <div style={{ display: 'flex', gap: "1rem", justifyContent: "end", paddingRight: "1rem" }}>
            {value.is_new_comment ? <BsFillChatSquareDotsFill color="#006CB0" /> : <></>}
            {onMark && <input type="checkbox" id={value.id} name={value.id} value={value.id}
                onChange={(event) => onMark(parseInt(event.target.value))} checked={markedItem.includes(value.id)}></input>}
            <FiList color="#006CB0" size="20" style={{ cursor: "pointer" }} onClick={(event) => handleOpenPopOver(event, value)} />
            <Popover
                open={open === value.id}
                anchorEl={anchorEl}
                onClose={handleClosePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{ p: 2 }}
            >
                <div style={{ display: 'flex', gap: ".2rem", justifyContent: "center", flexDirection: 'column' }}>

                    <div className="align-left gap-1" id="column1" style={{ cursor: "pointer" }} onClick={() => {
                        onEdit(value)
                        handleClosePopOver()
                    }}>
                        <FiEdit3 color="#006CB0" />
                        <p>Edit</p>
                    </div>

                    <div className="align-left gap-1" id="column2" style={{ cursor: "pointer" }} onClick={() => {
                        setConfirmDelete(value)
                        handleClosePopOver()
                    }}>
                        <FiTrash2 color="#C0392B" />
                        <p>Delete</p>
                    </div>

                </div>
            </Popover>

        </div>
    )


    const actionEdtDelChks = (onDelete, onEdit, onCheck, value) => {

        return (
            <div style={{ display: 'flex', gap: "1rem", justifyContent: "end", paddingRight: "1rem" }}>
                {value.expense_receipt ? <FiFileText color="#006CB0" /> : <></>}
                {value.reviewed ?
                    <FaCheckCircle color="#006CB0" style={{ cursor: role.can_review_expense ? "pointer" : "default" }} onClick={() => role.can_review_expense && onCheck(value)} /> :
                    <FiCheckCircle color="#006CB0" style={{ cursor: role.can_review_expense ? "pointer" : "default" }} onClick={() => role.can_review_expense && onCheck(value)} />}
                <FiEdit3 color="#006CB0" style={{ cursor: value.editable ? "pointer" : "default" }}
                    onClick={() => value.editable && onEdit(value)} />
                <FiTrash2 color="#C0392B" style={{ cursor: value.deletable ? "pointer" : "default" }}
                    onClick={() => value.deletable && setConfirmDelete(value)} />
            </div>
        )
    }

    const actionEdtDelChk = (onDelete, onEdit, onCheck, value) => {

        return (

            <div style={{ display: 'flex', gap: "1rem", paddingRight: "1rem" }}>
                {onMark && <input type="checkbox" id={value.id} name={value.id} value={value.id}
                    onChange={(event) => onMark(parseInt(event.target.value))} checked={markedItem.includes(value.id)}></input>}

                <FiList color="#006CB0" size="20" style={{ cursor: "pointer" }} onClick={(event) => handleOpenPopOver(event, value)} />

                <Popover
                    open={open === value.id}
                    anchorEl={anchorEl}
                    onClose={handleClosePopOver}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{ p: 2 }}
                >
                    <div style={{ display: 'flex', gap: ".2rem", justifyContent: "center", flexDirection: 'column' }}>

                        {value.reviewed ?
                            <div className="align-left gap-1" id="column1" style={{ cursor: role.can_review_expense ? "pointer" : "default" }}
                                onClick={() => {
                                    role.can_review_expense && onCheck(value)
                                    handleClosePopOver()
                                }}>
                                <FaCheckCircle color="#006CB0" />
                                <p>Unreviewed</p>
                            </div> :
                            <div className="align-left gap-1" id="column1" style={{ cursor: role.can_review_expense ? "pointer" : "default" }}
                                onClick={() => {
                                    role.can_review_expense && onCheck(value)
                                    handleClosePopOver()
                                }}>
                                <FiCheckCircle color="#006CB0" />
                                <p>Reviewed</p>
                            </div>
                        }

                        {value.editable && <div className="align-left gap-1" id="column2" style={{ cursor: value.editable ? "pointer" : "default" }}
                            onClick={() => {
                                value.editable && onEdit(value)
                                handleClosePopOver()
                            }}>
                            <FiEdit3 color="#006CB0" />
                            <p>Edit</p>
                        </div>}

                        {value.deletable && <div className="align-left gap-1" id="column3" style={{ cursor: value.deletable ? "pointer" : "default" }}
                            onClick={() => {
                                value.deletable && setConfirmDelete(value)
                                handleClosePopOver()
                            }}>
                            <FiTrash2 color="#C0392B" />
                            <p>Delete</p>
                        </div>}
                    </div>
                </Popover>


                {value.expense_receipt ? <FiFileText color="#006CB0" /> : <></>}
                {value.is_new_comment ? <BsFillChatSquareDotsFill color="#006CB0" /> : <></>}

                {value.reviewed ?
                    <div>
                        <FaCheckCircle color="#006CB0" />
                    </div> :
                    <div>
                        <FiCheckCircle color="#006CB0" />
                    </div>
                }

            </div>
        )
    }

    const actionEdtDelMore = (onDelete, onEdit, onMore, value) => (
        <div style={{ display: 'flex', gap: "1rem", justifyContent: "center", paddingRight: "1rem" }}>
            {onDelete && <FiTrash2 color="#C0392B" style={{ cursor: "pointer" }} onClick={() => setConfirmDelete(value)} />}
            <FiList color="#006CB0" size="20" style={{ cursor: "pointer" }} onClick={(event) => handleOpenPopOver(event, value)} />

            <Popover
                open={open === value.id}
                anchorEl={anchorEl}
                onClose={handleClosePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ p: 2 }}
            >
                <div style={{ display: 'flex', gap: ".2rem", justifyContent: "center", flexDirection: 'column' }}>
                    <div className="align-left gap-1" id="column1" style={{ cursor: "pointer" }}
                        onClick={() => {
                            onEdit(value)
                            handleClosePopOver()
                        }}>
                        <FiEdit3 color="#006CB0" style={{ cursor: "pointer" }} />
                        <p>Edit</p>
                    </div>
                    <div className="align-left gap-1" id="column2" style={{ cursor: "pointer" }} onClick={() => {
                        onMore(value)
                        handleClosePopOver()
                    }}>
                        <FiEdit3 color="#006CB0" style={{ cursor: "pointer" }} />
                        <p>Log</p>
                    </div>

                </div>

            </Popover>
        </div>
    )

    const confirmDeleteBody = () => (
        <Box>
            <p>Are you sure want to delete?</p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ButtonOulined name="Cancel" size="medium" onClickHandler={() => setConfirmDelete(false)} />
                <FilledRoundedButton name="Delete" color="red" size="medium" onClickHandler={() => {
                    onDelete(confirmDelete)
                    setConfirmDelete(false)
                }} />
            </div>
        </Box>
    )

    return (
        <Box>
            <Modal header="Confirm Delete" body={confirmDeleteBody} isOpen={confirmDelete || false} modalHandler={() => setConfirmDelete(false)} />
            <div className="table-body">
                <table style={{ width: '100%' }}>
                    <thead className='thead'>
                        <tr>
                            {header.map((th, i) => (
                                <th className={`${th.size}-header`} key={i}>{th.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {values.map((value, i) => (
                            <tr className={activeRow === value.id ? "table-row active-row" : "table-row"} key={i}>
                                <td>
                                    {onCheck ? actionEdtDelChk(onDelete, onEdit, onCheck, value) : onMore ? actionEdtDelMore(onDelete, onEdit, onMore, value) : actionEdtDel(onDelete, onEdit, value)}
                                </td>
                                {tableBody(value)}
                            </tr>
                        ))}
                        {total && <tr className="table-row">
                            {total()}
                        </tr>}
                    </tbody>
                </table>
            </div>
            <Pagination
                style={{ marginRight: '1rem', display: 'flex', justifyContent: 'right' }} color="primary"
                count={totalPage} variant="outlined" shape="rounded" page={page} onChange={handlePage}
            />
        </Box>
    )
}

export default SimpleTable