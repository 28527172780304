import React, { useState, useEffect } from 'react'
import TextInput from '../../../components/TextInput'
import Dropdown from '../../../components/Dropdown'
import { useDispatch } from 'react-redux'
import { getCourseBatch } from "../../../actions/comments"


function StudentInformation({ handleIncomeData, incomeInformation, readOnly, error, branches }) {
    const batchTypeOptions = [{ name: "Weekend", value: 'weekend' }, { name: "Weekday", value: 'weekday' }]
    const [batch, setBatch] = useState([])
    const [isReadOnly, setIsReadOnly] = useState({})
    const dispatch = useDispatch()

    console.log(branches);

    useEffect(() => {
        batch.length === 0 && getCourseName()
        if (readOnly === true) {
            let x = {}
            x['name'] = incomeInformation.name ? true : false
            x['phone_number'] = incomeInformation.phone_number ? true : false
            x['branch'] = incomeInformation.branch ? true : false
            x['email'] = incomeInformation.email ? true : false
            x['batch_type'] = incomeInformation.batch_ty ? true : false
            x['batch'] = incomeInformation.batch ? true : false
            setIsReadOnly(x)
        }
    }, [])

    const getCourseName = () => {
        dispatch(getCourseBatch('batch'))
            .then(response => {
                if (response.status === 200) {
                    setBatch(response.data.map(course => course.batch))
                }
            })
    }

    return (
        <div className="information-block">
            <p className="information-block-header">Student Information</p>
            <div className="information-row">
                <div>
                    <TextInput id="name" name="name" placeholder="Name" type="text" value={incomeInformation.name} onChangeHandler={handleIncomeData}
                        size="medium" readOnly={isReadOnly.name} required={true} error={error.name || false} errorMessage={error.name && error.name[0]} />
                </div>
                <div>
                    <TextInput id="phone_number" name="phone_number" placeholder="Phone" type="text" value={incomeInformation.phone_number}
                        onChangeHandler={handleIncomeData} required={true} size="medium" readOnly={isReadOnly.phone_number} error={error.phone_number || false} errorMessage={error.phone_number && error.phone_number[0]} />
                </div>
                {/* <div>
                    <Dropdown id="branch" name="branch" placeholder="Branch" defaultValue={incomeInformation.branch} options={branches}
                        onChangeHandler={handleIncomeData} required={true} size="medium" readOnly={isReadOnly.branch} error={error.branch || false} />
                </div> */}
                <div>
                    <TextInput id="email" name="email" placeholder="Email" type="email" value={incomeInformation.email} onChangeHandler={handleIncomeData}
                        size="medium" readOnly={isReadOnly.email} required={true} error={error.email || false} errorMessage={error.email && error.email[0]} />
                </div>
                <div>
                    <Dropdown id="batch_type" name="batch_type" placeholder="Batch Type" onChangeHandler={handleIncomeData} size="medium"
                        options={batchTypeOptions} required={false} defaultValue={incomeInformation.batch_type || "DEFAULT"} disabled={isReadOnly.batch_type} error={error.batch_type || false} />
                </div>
                <div>
                    <TextInput id="batch" name="batch" placeholder="Batch" type="text" value={incomeInformation.batch} onChangeHandler={handleIncomeData}
                        size="medium" list={true} readOnly={isReadOnly.batch} error={error.batch || false} errorMessage={error.batch && error.batch[0]} />
                    <datalist id="options">
                        {batch.map(option => (
                            <option value={option}>{option}</option>
                        ))}
                    </datalist>
                </div>
            </div>
        </div>
    )
}

export default StudentInformation